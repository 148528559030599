.social {
    border-radius: 4px;
    width: 36px;
    height: 28px;
    padding: 4px 0;
    margin-right: 5px;
    &:hover {
        opacity: 0.7;
        color: #fff;
        text-decoration: none;
    }
}