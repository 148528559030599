.logo {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(230deg)
    brightness(104%) contrast(102%);
}

.logoWrapper {
  height: 34px;
  width: 38px;
  position: relative;
}

.menuContainer {
    position: absolute;
    top: 42px;
    padding: 15px;
    width: 240px;
     height: 225px; 
    background: #000;
    right: 0;
    transition: right .4s ease-in-out;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    
}


.navRedButton,
  .navRedButtonMobile {
    background-color: #d7000e;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #e01a1a;
    text-transform: uppercase;
    transition: 0.3s;
    color: white;
    font-family: Arial Black,sans-serif;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;

    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #98040e;
    }
    .navRedButtonMobile {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navbar {
      align-items: baseline;
      padding: 0.5rem;
  
      .components {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // gap: 10px;
        .domain {
          color: white;
          margin: 0;
          display: block;
          font-family: "Arial Black", Helvetica, sans-serif;
          font-size: 18px;
          text-transform: uppercase;
        }
        
      }}
      .navRedButton {
        margin: auto;
        margin-top: 10px;
      }
    }
  


@media screen and (max-width: 486px) {
  .navbar {
    align-items: center;
    .components {
      .navRedButton {
        display: none;
      }
    }
    .navRedButtonMobile {
      display: block;
      width: 100%;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 418px) {
  .navbar {
    .components {
      .domain {
        font-size: 14px;
      }
    }
    .navRedButtonMobile {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 350px) {
  .navbar {
    
      .domain {
        font-size: 10px;
      }
    
    .navRedButtonMobile {
      font-size: 10px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

@media screen and (max-width: 276px) {
  .navbar {
    
      .domain {
        display: none;
      }
    
  }
}