.about {
  color: #000000de;
  h3 {
    font: 700 16px/28px Roboto, Helvetica Neue, sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  p {
    max-width: 970px;
    font-family: 'Lato Light', sans-serif;
    margin: 0 0 12px;

    font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
    letter-spacing: normal;
    color: #000000de;
  }
}
