@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
  width: 100% !important;
  height: 100% !important;
}

.swiper-scrollbar-drag {
  top: 2px;
  height: 10px;
  border-radius: 0px;
  background: rgb(110 106 106 / 50%);

  &:hover {
    background: rgb(53 47 47 / 50%);
  }
}

ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.ant-spin-dot-item {
  background: rgb(22 163 74) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

@include placeholder {
  color: #777 !important;
  opacity: 1 !important;
}

.col-sm-12 {
  position: relative;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.news-card {
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    font-family: Arial Black, sans-serif;
    color: #6f6f6f;
  }
  .card-body {
    p {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      color: #6f6f6f;
      font-family: Lato Light, sans-serif;
      text-align: left;
    }
  }
}

.info-box-ctr {
  z-index: 1;
  color: #fff;

  p {
    color: #fff;
    break-inside: avoid;
    font-size: 14px;
  }
  ul {
    break-inside: avoid;
  }
  li {
    color: #fff;
    break-inside: avoid;
    font-size: 12px;
  }
  span {
    color: #fff;
    break-inside: avoid;
    font-size: 12px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    break-inside: avoid;
  }
  h1 {
    font-size: 26px;
    text-align: center;
    margin: auto;
    padding-bottom: 20px;
    font-family: Arial Black, sans-serif;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    font-size: 9px;
  }

  .tc-long {
    h2 {
      font-size: 21px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 2rem;
      // max-width: 66%;
    }
    .tc-columns {
      column-count: 1;
      max-width: 66%;
      column-gap: 2.5rem;
      margin: auto;
      .section,
      p,
      span,
      ul,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        text-align: left;
      }
      h3 {
        font-size: 14px;
      }
    }

    .section,
    p,
    span,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  .tc-short {
    display: none;
  }
}
